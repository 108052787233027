<template>
  <div v-if="vendor" id="reservationView" :style="{backgroundImage: 'url(' + vendor.bannerURL + ')'}">
    <CustomVars></CustomVars>
    <div class="main_card">
      <div class="header_card">
        <div class="cont_icon_header"
          :class="{'info_res' : (validaReservation && validaDate && error === null && (validaReservation.codeStatus === 0 || validaReservation.codeStatus === 1) && msgConfirmation === null && !isCancel) || msgConfirmation,
          'cancel_res' : (validaReservation && validaDate && error === null && (validaReservation.codeStatus === 2 || validaReservation.codeStatus === 3 || validaReservation.codeStatus === 4 || validaReservation.codeStatus === 5)) || isCancel || error !== null,
          'time_res' : !validaDate}"
        >
          <i class="icons icon_header"></i>
        </div>
        <div class="cont_logo">
          <img :src="vendor.thumbnailURL" class="img_logo" />
        </div>
      </div>
      <div class="content_card">
        <div class="info_card" v-if="validaReservation && validaDate && error === null && (validaReservation.codeStatus === 0 || validaReservation.codeStatus === 1) && msgConfirmation === null">
          <div class="cont_name ffr fw500">
            {{ $t('hi') }} <span class="ffr fw700">{{ validaReservation.displayName }}</span>
          </div>
          <div class="cont_reserve ffr fw500" v-if="!isCancel && validaReservation.balancePaid <= 0 || validaReservation.isWarranty === 1">
            {{ $t('reservationOf') }}
          </div>
          <div class="cont_reserve ffr fw500" v-if="!isCancel && validaReservation.balancePaid > 0 && validaReservation.isWarranty === 0">
            {{ $t('reservationView.text1') }}
          </div>
          <div class="cont_reserve ffr fw500" v-if="isCancel">
            {{ $t('reservationView.text2') }}
          </div>
          <div class="info_reserve columns" :class="{'is_cancel' : isCancel}">
            <div class="main_info column is-3 is-2-mobile p-0">
              <div class="cont_icon">
                <i class="icons icon_people"></i>
              </div>
              <span class="text_res people_res ffr fw500">{{ validaReservation.people }}</span>
            </div>
            <div class="main_info column is-5 p-0">
              <div class="cont_icon">
                <i class="icons icon_calendar"></i>
              </div>
              <span class="text_res date_res ffr fw500">{{ parseInt(validaReservation.date) | moment('timezone', vendorTimezone, 'MMMM DD') }}</span>
            </div>
            <div class="main_info column is-4 is-5-mobile p-0">
              <div class="cont_icon">
                <i class="icons icon_clock"></i>
              </div>
              <span class="text_res hour_res ffr fw500">{{ parseInt(validaReservation.date) | moment('timezone', vendorTimezone, 'hh:mm a') }}</span>
            </div>
          </div>
          <div class="cont_img_info"></div>
          <div class="text_cancel ffr fw500" v-if="isCancel">
            {{ $t('reservationView.text3') }}
          </div>
          <div class="cont_btns_v2" v-if="infReservation.isUserConfirmed === null && showButtonConfirmation == 1 && !isCancel && (!params.activeCompanion || ( params.activeCompanion && (infReservation.people - 1) == 0))">
            <button
              class="btn_action reconfirm ffr fw500"
              v-if="infReservation.isUserConfirmed === null && showButtonConfirmation == 1 && !isCancel"
              @click="setUserConfirmReservation(infReservation, userConfirmation)"
            >
            {{ $t('reservationView.text4') }}
            </button>
            <button
              class="btn_action edit_res ffr fw500"
              v-if="!isCancel"
              @click="setDataEditReservation(infReservation)"
            >
              {{ $t('editBooking') }}
            </button>
            <button
              class="btn_action cancel_res ffr fw500"
              v-if="!isCancel"
              @click="setDataCancelarReservation(infReservation)"
            >
              {{ $t('cancelBooking') }}
            </button>
          </div>
          <div class="cont_btns" :class="{'is_cancel' : isCancel}" v-else>
            <button
              class="btn_action ffr fw500"
              v-if="infReservation.isUserConfirmed === null && showButtonConfirmation == 1 && !isCancel"
              @click="setUserConfirmReservation(infReservation, userConfirmation)"
            >
            {{ $t('reservationView.text4') }}
            </button>
            <button
              class="btn_action ffr fw500"
              v-if="!isCancel && validaReservation.isWarranty === 0"
              @click="setDataEditReservation(infReservation)"
            >
              {{ $t('editBooking') }}
            </button>
            <button
              class="btn_action ffr fw500"
              v-if="!isCancel"
              @click="setDataCancelarReservation(infReservation)"
            >
              {{ $t('cancelBooking') }}
            </button>
            <button
              class="btn_action ffr fw500"
              v-if="params.activeCompanion && (infReservation.people - 1) > 0 && !isCancel"
              @click="setCompanion(infReservation)"
            >
              {{ $t('diners2') }}
            </button>
            <button
              class="btn_action ffr fw500"
              v-if="isCancel"
              @click="reserveAgain()"
            >
            {{ $t('reservationView.text5') }}
            </button>
          </div>
          <div class="cont_footer">
            <div class="text_footer ffr fw300">
              {{ $t('reservationView.text6') }} <span class="ffr fw500">{{ vendor.phone }}</span>
            </div>
            <div class="logo_precompro"></div>
          </div>
        </div>
        <div class="info_card is_cancel" v-if="validaReservation && validaDate && error === null && (validaReservation.codeStatus === 2 || validaReservation.codeStatus === 3 || validaReservation.codeStatus === 4 || validaReservation.codeStatus === 5)">
          <div class="cont_status">
            <span class="mr_user ffr fw500">{{ $t('user') }}</span>
            <span class="info_status ffr fw700">{{ $t('reservationView.text7') }}<br>{{ $t('reservationView.text8') }} {{ validaReservation.status }}.</span>
          </div>
          <div class="cont_btns is_cancel">
            <button
              class="btn_action is_cancel ffr fw500"
              @click="reserveAgain()"
            >
            {{ $t('reservationView.text5') }}
            </button>
          </div>
          <div class="cont_footer status">
            <div class="text_footer ffr fw300">
              {{ $t('reservationView.text6') }} <span class="ffr fw500">{{ vendor.phone }}</span>
            </div>
            <div class="logo_precompro status"></div>
          </div>
        </div>
        <div class="info_card is_cancel" v-if="error !== null">
          <div class="cont_status">
            <span class="mr_user ffr fw500">{{ $t('user') }}</span>
            <span class="info_error ffr fw700">{{ error }}</span>
          </div>
          <div class="cont_footer error">
            <div class="text_footer ffr fw300">
              {{ $t('reservationView.text6') }} <span class="ffr fw500">{{ vendor.phone }}</span>
            </div>
            <div class="logo_precompro status"></div>
          </div>
        </div>
        <div class="info_card is_cancel" v-if="msgConfirmation !== null">
          <div class="cont_status">
            <span class="mr_user ffr fw500">{{ $t('user') }}</span>
            <span class="info_error ffr fw700">{{ msgConfirmation }}</span>
          </div>
          <div class="cont_footer error">
            <div class="text_footer ffr fw300">
              {{ $t('reservationView.text6') }} <span class="ffr fw500">{{ vendor.phone }}</span>
            </div>
            <div class="logo_precompro status"></div>
          </div>
        </div>
        <div class="info_card is_cancel"  v-if="!validaDate">
          <div class="cont_status">
            <span class="mr_user ffr fw500">{{ $t('user') }}</span>
            <span class="info_error ffr fw700">{{ $t('reservationView.text9') }}</span>
          </div>
          <div class="cont_btns is_cancel">
            <button
              class="btn_action is_cancel ffr fw500"
              @click="reserveAgain()"
            >
            {{ $t('reservationView.text5') }}
            </button>
          </div>
          <div class="cont_footer status">
            <div class="text_footer ffr fw300">
              {{ $t('reservationView.text6') }} <span class="ffr fw500">{{ vendor.phone }}</span>
            </div>
            <div class="logo_precompro status"></div>
          </div>
        </div>
      </div>
    </div>
    <ModalWaitingList v-if="popupWaitingList" v-model="popupWaitingList"></ModalWaitingList>
    <ModalSuccessWaitingList v-if="popupSuccessWaitingList" v-model="popupSuccessWaitingList"></ModalSuccessWaitingList>
    <EditReservation
      :edit="true"
      :type="'email'"
      @reservation-select="reservationSelect"
      v-if="(editReservation && !popupWaitingList)"
    ></EditReservation>
    <PoliticaConfirmationModal :colorCheckout="design.colorCheckout" v-if="design"></PoliticaConfirmationModal>
  </div>
</template>
<script>
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import PoliticaConfirmationModal from '@/components/Checkout/politicaConfirmationModal';
import EditReservation from '@/components/Account/editReservation';
import CancelarReservation from '@/components/Account/cancelarReservation';
import ModalWaitingList from '@/components/Select/ModalWaitingList.vue';
import ModalSuccessWaitingList from '@/components/Select/ModalSuccessWaitingList.vue';
import modalCompanion from '@/components/Checkout/modalCompanionNew';
import CustomVars from '@/components/customVars';
export default {
  components: {
    EditReservation,
    PoliticaConfirmationModal,
    CustomVars,
    ModalWaitingList,
    ModalSuccessWaitingList
  },
  data () {
    return {
      idReservation: null,
      infReservation: [],
      validaReservation: [],
      error: null,
      now: this.$moment(),
      interval: null,
      editOnly: false,
      userConfirmation: null,
      msgConfirmation: null,
      showButtonConfirmation: 0,
      validaDate: true,
      isCancel: false
    };
  },
  computed: {
    ...mapGetters({
      systemDate: [globalTypes.getters.serverTime],
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults],
      popupWaitingList: whiteLabelTypes.getters.popupWaitingList,
      popupSuccessWaitingList: whiteLabelTypes.getters.popupSuccessWaitingList
    })
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    let subdomain = this.subDomain();
    const vendorUrl = subdomain;
    this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
    this.$store
      .dispatch({ type: 'whiteLabel:vendorId', data: { url: vendorUrl } })
      .then(response => {
        if (response.data.vendorId) {
          this.$store
            .dispatch({
              type: 'whiteLabel:vendor',
              data: { vendorId: response.data.vendorId }
            })
            .then(data => {
              document.title = data.data.vendor.displayName
                ? data.data.vendor.displayName
                : '';
              document.querySelector("link[rel*='icon']").href =
                (data.data.vendor.photoURL !== null) ? data.data.vendor.photoURL : data.data.vendor.thumbnailURL;
            });
          this.$store.dispatch({
            type: 'reservation:schedules',
            data: { vendorId: response.data.vendorId }
          });
          this.$store.dispatch({
            type: 'event:allEvents',
            data: { vendorId: this.$store.getters['whiteLabel:vendorId'] }
          });
          this.$store.dispatch({
            type: 'reservation:times',
            data: { vendorId: this.$store.getters['whiteLabel:vendorId'] }
          });
          this.$store.dispatch({
            type: 'global:getServerTime',
            data: {
              timezone: this.vendor.timezone
            }
          });
        }
      })
      .finally(() => {
        try {
          let id = this.decryptMx(this.$route.query.data);
          this.idReservation = this.encrypt(parseInt(id));
          this.userConfirmation = this.$route.query.userConfirmation;
          this.showButtonConfirmation = (this.$route.query.isRecordation !== undefined) ? 1 : 0;
          this.$store
            .dispatch({
              type: 'reservation:reservationNew',
              data: { reservationId: this.idReservation }
            })
            .then(({ data }) => {
              if (data.length > 0) {
                this.validaReservation = data[0];
              } else {
                this.error = 'La información de la reserva es incorrecta!.';
              }
            });
          this.$store
            .dispatch({
              type: 'reservation:reservationDetailById',
              data: { reservationId: this.idReservation }
            })
            .then(data => {
              if (Object.keys(data).length > 0) {
                this.$store.commit(
                  reservationTypes.mutations.setSelectedDate,
                  parseInt(data.date)
                );
                this.infReservation = data;
                const dateSistem = this.$moment(this.systemDate);
                const diff = dateSistem.diff(this.$moment(this.infReservation.fechaCompleta), 'hours', true);
                if (diff >= 0) {
                  this.validaDate = false;
                }
              } else {
                this.error = 'La información de la reserva es incorrecta!.';
              }
            });
          this.interval = window.setInterval(this.updateInfoReservation, 6000);
        } catch (err) {
          this.error = 'La información de la reserva es incorrecta!.';
        }
      });
    this.serverTime();
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  },
  methods: {
    serverTime () {
      this.$store.dispatch({
        type: 'global:getServerTime',
        data: {
          timezone: this.vendor.timezone
        }
      })
        .then(({ serverTime }) => {
          this.now = this.$moment(serverTime);
        });
    },
    reserveAgain () {
      window.location.href = '/';
    },
    setCompanion (info) {
      this.$buefy.modal.open({
        parent: this,
        component: modalCompanion,
        props: {
          infoReserva: info
        },
        events: {
        },
        hasModalCard: true,
        customClass: 'modal-companion-redesign',
        trapFocus: true,
        canCancel: false
      });
    },
    isMobile () {
      return this.$store.state.isMobile;
    },
    validateTimeUpdate (date, anticipationTime) {
      const dateFormatted = this.$moment(parseInt(date)).valueOf();
      const nowTimestamp = this.now.valueOf();
      const timeDifference = dateFormatted - nowTimestamp;
      return timeDifference >= anticipationTime && timeDifference > 0;
    },
    reservationSelect () {
      this.$store
        .dispatch({
          type: 'reservation:reservationDetailById',
          data: { reservationId: this.idReservation }
        })
        .then(data => {
          if (Object.keys(data).length > 0) {
            this.$store.commit(
              reservationTypes.mutations.setSelectedDate,
              parseInt(data.date)
            );
            this.infReservation = data;
          } else {
            this.error = 'La información de la reserva es incorrecta!.';
          }
        });
    },
    setDataEditReservation (data) {
      const info = JSON.parse(JSON.stringify(data));
      let bol = this.validateTimeUpdate(parseInt(info.date), this.params.updateTime);
      let date = new Date(this.selectedDate);
      date = parseInt(this.$moment(date).hours(0).minutes(0).seconds(0).millisecond(0).format('x'));
      if (bol) {
        if (info.balancePaid === null || info.balancePaid === 0) {
          this.$store.commit(
            reservationTypes.mutations.setDataReservationEdit,
            info
          );
          this.$store.commit(
            reservationTypes.mutations.setSelectedPeople,
            parseInt(info.people)
          );
          this.$store.commit(reservationTypes.mutations.setSelectedDate, date);
          this.$store.commit(reservationTypes.mutations.setSelectedZone, info.zoneId);
          this.$store.commit(
            reservationTypes.mutations.setSelectedHour,
            parseInt(info.date)
          );
          this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
          this.$store.dispatch({
            type: 'whiteLabel:avalibilityHour',
            data: {
              timestamp: parseInt(date),
              vendorId: this.$store.getters['whiteLabel:vendorId'],
              people: parseInt(info.people),
              zone: this.selectedZone,
              typeReservation: info.typeReservation,
              tableId: this.selectedTable
            }
          });
          this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
          this.$store.commit(
            reservationTypes.mutations.setEditReservation,
            !this.editReservation
          );
        } else {
          this.$store.commit(
            whiteLabelTypes.mutations.setShowModalPolitica,
            true
          );
          this.$store.commit(
            whiteLabelTypes.mutations.setCancelUpdateTime,
            this.params.updateTime
          );
        }
      } else {
        this.$store.commit(
          reservationTypes.mutations.setDataReservationEdit,
          info
        );
        this.$store.commit(reservationTypes.mutations.setSelectedZone, info.zoneId);
        this.$store.commit(
          reservationTypes.mutations.setSelectedPeople,
          parseInt(info.people)
        );
        this.$store.commit(reservationTypes.mutations.setSelectedDate, date);
        this.$store.commit(
          reservationTypes.mutations.setSelectedHour,
          parseInt(info.date)
        );
        this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
        this.$store.dispatch({
          type: 'whiteLabel:avalibilityHour',
          data: {
            timestamp: parseInt(date),
            vendorId: this.$store.getters['whiteLabel:vendorId'],
            people: parseInt(info.people),
            zone: this.selectedZone,
            typeReservation: info.typeReservation,
            tableId: this.selectedTable
          }
        });
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
        this.$store.commit(
          reservationTypes.mutations.setEditReservation,
          !this.editReservation
        );
      }
    },
    setDataCancelarReservation (data) {
      const info = JSON.parse(JSON.stringify(data));
      let date = new Date(this.selectedDate);
      date = this.$moment(date).format('YYYY-MM-DD');
      date = new Date(date + ' 00:00:00').getTime();
      this.$store.commit(
        reservationTypes.mutations.setSelectedPeople,
        parseInt(info.people)
      );
      this.$store.commit(reservationTypes.mutations.setSelectedDate, date);
      this.$store.commit(
        reservationTypes.mutations.setSelectedHour,
        parseInt(info.date)
      );
      if (info.balancePaid > 0) {
        let bol = this.validateTimeUpdate(
          parseInt(info.date),
          this.params.cancelledTime
        );
        if (!bol) {
          this.$store.commit(
            reservationTypes.mutations.setCancelarReservation,
            info
          );
          this.openCancelModal();
        } else {
          this.$store.commit(
            whiteLabelTypes.mutations.setShowModalPolitica,
            true
          );
          this.$store.commit(
            whiteLabelTypes.mutations.setCancelUpdateTime,
            this.params.cancelledTime
          );
        }
      } else {
        this.$store.commit(
          reservationTypes.mutations.setCancelarReservation,
          info
        );
        this.openCancelModal();
      }
    },
    setUserConfirmReservation (reservationData, typerConfirmation) {
      this.$store
        .dispatch({
          type: 'reservation:setUserConfirmReservation',
          data: {
            reservationId: this.idReservation,
            typerConfirmation: typerConfirmation
          }
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.msgConfirmation = 'Tu reserva ha sido reconfirmada correctamente.';
          } else {
            this.error = 'No se puedo confirmar tu reserva. Comunícate con el restaurante.';
          }
        });
    },
    updateInfoReservation () {
      this.$store
        .dispatch({
          type: 'reservation:reservationNew',
          data: { reservationId: this.idReservation }
        })
        .then(({ data }) => {
          if (data.length > 0) {
            this.validaReservation = data[0];
          } else {
            this.error = 'La información de la reserva es incorrecta!.';
          }
        });
    },
    openCancelModal () {
      let instance = this;
      this.$buefy.modal.open({
        parent: instance,
        props: {
          type: 'email'
        },
        component: CancelarReservation,
        hasModalCard: true,
        canCancel: false,
        events: {
          cancel () {
            instance.isCancel = true;
          }
        }
      });
    }
  },
  destroyed () {
    clearInterval(this.interval);
  }
};
</script>
<style lang="scss" >
  @import '@/assets/styles/Reservation/reservation.scss';
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700');
</style>
